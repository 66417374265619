<template>
  <div>
    <router-view />
  </div>
  
</template>

<style lang="scss">
@import url(./global.scss);
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
